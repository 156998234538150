import { createSlice } from '@reduxjs/toolkit';

export const sipTrunksSlice = createSlice({
	name: 'connections',
	initialState: {
		data: [],
	},
	reducers: {
		setDataSipTrunks: (state, data) => {
			state.data = data.payload;
		},
	},
});

export const { setDataSipTrunks } = sipTrunksSlice.actions;

export default sipTrunksSlice.reducer;
