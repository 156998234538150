export const columnsBalances = [
	{
		headerName: 'Дата пополнения',
		field: 'createAt',
		width: 200,
	},
	{
		headerName: 'Лицензионный ключ',
		field: 'key',
		width: 300,
	},
	{
		headerName: 'Количество звонков',
		field: 'quantity_calls',
		width: 190,
	},
	{
		headerName: 'Статус',
		field: 'status',
		width: 50,
	},
];

export const columnsCalls = [
	{
		headerName: 'Дата',
		field: 'createAt',
		width: 450,
	},
	{
		headerName: 'Сервисный номер',
		field: 'service_contact',
		width: 400,
	},
	{
		headerName: 'Телефон',
		field: 'phone',
		width: 350,
	},
	{
		headerName: 'Статус',
		field: 'success',
		width: 220,
	},
];

export const columnsUsers = [
	{
		headerName: 'Имя',
		field: 'name',
		width: 350,
	},
	{
		headerName: 'Телефон',
		field: 'phone',
		width: 250,
	},
	{
		headerName: 'Баланс',
		field: 'balance',
		width: 350,
	},
	{
		headerName: 'Статус',
		field: 'status',
		width: 250,
	},
];

export const columnsAdmins = [
	{
		headerName: 'Имя',
		field: 'name',
		width: 350,
	},
	{
		headerName: 'Телефон',
		field: 'phone',
		width: 250,
	},
];
