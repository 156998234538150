import React from 'react';
import './style.css';
import Modal from '@mui/material/Modal';
import CustomCheckbox from '../../checkboxes/customCheckbox';
import CustomInput from '../../inputs/customInput';
import CencelButton from '../../buttons/cencelButton';
import CustomButton from '../../buttons/customButton';
import { v4 as uuidv4 } from 'uuid';
import CloseIcon from '@mui/icons-material/Close';
import { createApplication } from '../../../api/apps';

const AddAppModal = (props) => {
	const { open, setClose } = props;
	const [selectOTP, setSelectOTP] = React.useState(true);
	const [selectOAUTH2, setSelectOAUTH2] = React.useState(false);
	const [appName, setAppName] = React.useState('');
	const [secretKey, setSecretKey] = React.useState(uuidv4().replace(/-/g, ''));
	const [authCallback, setAuthCallback] = React.useState('');
	const [validName, setValidName] = React.useState('root');
	const [validSecretKey, setValidSecretKey] = React.useState('success');
	const [validCallback, setValidCallback] = React.useState('root');
	const [errorNameMessage, setErrorNameMessage] = React.useState('');
	const [errorKeyMessage, setErrorKeyMessage] = React.useState('');
	const [errorCallbackMessage, setErrorCallbackMessage] = React.useState('');

	const closeModal = () => {
		setClose(false);
	};

	const handleSelectOTP = (value) => {
		if (!value && !selectOAUTH2) return;
		else setSelectOTP(value);
	};

	const handleSelectOAUTH2 = (value) => {
		if (!value && !selectOTP) return;
		else setSelectOAUTH2(value);
	};

	const changeAppName = (value) => {
		setAppName(value);
		if (value.length) setValidName('success');
		else setValidName('error');
	};

	const changeSecretKey = (value) => {
		setSecretKey(value);
		if (value.length) setValidSecretKey('success');
		else setValidSecretKey('error');
	};

	const changeCallback = (value) => {
		setAuthCallback(value);
		if (value.length) setValidCallback('success');
		else setValidCallback('error');
	};

	const addApplication = async () => {
		if (
			(selectOAUTH2 &&
				validName === 'success' &&
				validSecretKey === 'success' &&
				validCallback === 'success') ||
			(!selectOAUTH2 &&
				selectOTP &&
				validName === 'success' &&
				validSecretKey === 'success')
		) {
			const body = {
				client_secret: secretKey,
				redirect_uri: authCallback,
				name: appName,
				auth_type: selectOTP && selectOAUTH2 ? 0 : selectOTP ? 1 : 2,
			};
			await createApplication({ body });
			closeModal();
		} else if (validName !== 'success') {
			setValidName('error');
			setErrorNameMessage('Название приложения не введено');
		} else if (validSecretKey !== 'success') {
			setValidSecretKey('error');
			setErrorKeyMessage('Секретный ключ не введен');
		} else if (selectOAUTH2 && validCallback !== 'success') {
			setValidCallback('error');
			setErrorCallbackMessage('Адрес авторизации не введен');
		}
	};

	return (
		<Modal
			open={open}
			onClose={closeModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<div className="add_app_modal">
				<h2>Добавить приложение</h2>
				<div className="add_app_modal__selectors">
					<CustomCheckbox
						label="OTP"
						checked={selectOTP}
						changeSelect={handleSelectOTP}
					/>
					<CustomCheckbox
						label="OAUTH 2"
						checked={selectOAUTH2}
						changeSelect={handleSelectOAUTH2}
					/>
				</div>
				<div className="add_app_modal__row">
					<span className="add_app_modal__row__label">
						Название приложения <span style={{ color: 'red' }}>*</span>
					</span>
					<CustomInput
						mode={validName}
						value={appName}
						changeInput={changeAppName}
						errorMessage={errorNameMessage}
						focus={true}
					/>
				</div>
				<div className="add_app_modal__row">
					<span className="add_app_modal__row__label">
						Секретный ключ <span style={{ color: 'red' }}>*</span>
					</span>
					<CustomInput
						mode={validSecretKey}
						value={secretKey}
						changeInput={changeSecretKey}
						errorMessage={errorKeyMessage}
					/>
				</div>
				<div
					className={
						selectOAUTH2
							? 'add_app_modal__row'
							: 'add_app_modal__row hidden_block'
					}
				>
					<span className="add_app_modal__row__label">
						Адрес авторизации в приложении (callback) для OAUTH 2{' '}
						<span style={{ color: 'red' }}>*</span>
					</span>
					<CustomInput
						mode={validCallback}
						value={authCallback}
						changeInput={changeCallback}
						placeholder="https://example.com/auth"
						errorMessage={errorCallbackMessage}
					/>
				</div>
				<div className="add_app_modal__row__buttons">
					<div className="add_app_modal__row__buttons_right">
						<CencelButton text={'Отмена'} onClick={closeModal} />
						<CustomButton text="Добавить" onClick={addApplication} />
					</div>
				</div>
				<CloseIcon className="close_button" onClick={closeModal} />
			</div>
		</Modal>
	);
};

export default AddAppModal;
