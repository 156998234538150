import React from 'react';
import './style.css';
import Popover from '@material-ui/core/Popover';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import axios from 'axios';
import { URL_SERVER } from '../../../utils/constants';
import { setDataSipTrunks } from '../../../redux/actions/siptrunks';
import { setSession } from '../../../redux/actions/session';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles({
	popover: {
		'& .MuiPopover-paper': {
			padding: 20,
			display: 'flex',
			flexDirection: 'column',
			alignAtems: 'center',
			'& .MuiButtonBase-root': {
				marginTop: 10,
			},
			'& .MuiFormControl-root': {
				marginBottom: 10,
			},
		},
	},
});

const AddConnection = () => {
	const dispatch = useDispatch();
	const connections = useSelector((state) => state.siptrunks);
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [username, setUsername] = React.useState();
	const [password, setPassword] = React.useState();
	const [server, setServer] = React.useState();

	let phoneRef, passwordRef, serverRef;

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const changePhone = (e) => {
		setUsername(e.target.value);
	};

	const changePassword = (e) => {
		setPassword(e.target.value);
	};

	const changeServer = (e) => {
		setServer(e.target.value);
	};

	const create = (e) => {
		setAnchorEl(e.target);
	};

	const save = () => {
		(async () => {
			const body = {
				username: username,
				secret: password,
				host: server,
			};
			if (!username) phoneRef.focus();
			else if (!password) passwordRef.focus();
			else if (!server) serverRef.focus();
			else {
				await axios
					.post(`${URL_SERVER}/api/v1/siptrunks`, body, {
						withCredentials: true,
					})
					.then((res) => {
						const connect = [...connections.data];
						connect.push(res.data);
						dispatch(setDataSipTrunks(connect));
						setAnchorEl(null);
					})
					.catch((err) => {
						console.log(err.message);
						if (err.message.includes('401')) {
							dispatch(setSession(false));
						}
					});
			}
		})();
	};

	return (
		<div className="add_connection">
			<Button variant="outlined" color="primary" onClick={create}>
				Добавить
			</Button>
			<Popover
				className={classes.popover}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<FormControl variant="outlined">
					<TextField
						id="outlined-adornment-phone"
						label="Телефон"
						onChange={changePhone}
						inputRef={(n) => (phoneRef = n)}
						value={username}
					/>
				</FormControl>
				<FormControl variant="outlined">
					<TextField
						label="Пароль"
						onChange={changePassword}
						inputRef={(n) => (passwordRef = n)}
						value={password}
					/>
				</FormControl>
				<FormControl variant="outlined">
					<TextField
						label="Сервер"
						onChange={changeServer}
						inputRef={(n) => (serverRef = n)}
						value={server}
					/>
				</FormControl>
				<Button variant="outlined" color="primary" onClick={save}>
					Сохранить
				</Button>
			</Popover>
		</div>
	);
};

export default AddConnection;
