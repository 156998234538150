import React from 'react';
import './style.css';
import axios from 'axios';
import { URL_SERVER } from '../../utils/constants';
import { setDataSipTrunks } from '../../redux/actions/siptrunks';
import { setSession } from '../../redux/actions/session';
import { useSelector, useDispatch } from 'react-redux';
import SettingsSip from '../settingsSip';
import AddConnection from '../buttons/addConnection';

const ConnectionsSip = () => {
	const dispatch = useDispatch();
	const connections = useSelector((state) => state.siptrunks);

	React.useEffect(() => {
		(async () => {
			await axios
				.get(`${URL_SERVER}/api/v1/siptrunks`, { withCredentials: true })
				.then((res) => {
					dispatch(setDataSipTrunks(res.data));
				})
				.catch((err) => {
					if (err.message.includes('401')) {
						console.log(err.message);
						dispatch(setSession(false));
					}
				});
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="connections_sip">
			{connections.data.map((connection) => (
				<SettingsSip connection={connection} key={connection.id} />
			))}
			<AddConnection />
		</div>
	);
};

export default ConnectionsSip;
