import React from 'react';
import axios from 'axios';
import { URL_SERVER } from '../../utils/constants';
import { useDispatch } from 'react-redux';
import { setDataProfile } from '../../redux/actions/profile';
import { setSession } from '../../redux/actions/session';
import { AppBar, Tabs, Tab, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './style.css';
import AdminProfile from '../adminProfile';
import Users from '../users/index';
import Admins from '../admins/index';
import ConnectionsSip from '../connectionsSip';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: '#fff',
		maxWidth: 1300,
		'& .PrivateTabIndicator-colorPrimary-7': {
			backgroundColor: 'dodgerblue',
		},
	},
	app: {
		boxShadow: 'none',
	},
	tabs: {
		backgroundColor: '#fff',
		boxShadow: 'none',
		color: '#000',
	},
	tab: {
		minWidth: 300,
	},
}));

export function SimpleTabs() {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			<AppBar position="static" className={classes.app}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="simple tabs example"
					centered
					indicatorColor="primary"
					className={classes.tabs}
				>
					<Tab label="Профиль" {...a11yProps(0)} className={classes.tab} />
					<Tab label="Пользователи" {...a11yProps(1)} className={classes.tab} />
					<Tab
						label="Администраторы"
						{...a11yProps(2)}
						className={classes.tab}
					/>
					<Tab
						label="Настройки SIP"
						{...a11yProps(3)}
						className={classes.tab}
					/>
				</Tabs>
			</AppBar>
			<TabPanel value={value} index={0}>
				<AdminProfile />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Users />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<Admins />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<ConnectionsSip />
			</TabPanel>
		</div>
	);
}

const AdminPanel = ({ showApp }) => {
	const dispatch = useDispatch();

	React.useEffect(() => {
		(async () => {
			await axios
				.get(`${URL_SERVER}/api/v1/users`, { withCredentials: true })
				.then((res) => {
					dispatch(setDataProfile(res.data[0]));
					if (!res.data[0].admin) {
						window.location.href = '/';
					}
					showApp();
				})
				.catch((err) => {
					if (err.message.includes('401')) {
						showApp();
						console.log(err.message);
						dispatch(setSession(false));
					}
				});
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="main">
			<SimpleTabs />
		</div>
	);
};

export default AdminPanel;
